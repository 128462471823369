export const environment = {
  production: true,
  appVersion: 'v1',
  USERDATA_KEY: 'auth_esafebox_web_2024',
  isMockEnabled: false,
  mapsAPIKey: '',
  publicWebsiteUrl: 'https://esafebox.apptechasia.app',
  apiUrl: 'https://api.esafebox.apptechasia.app/app/',
  defaultFormFieldIds: [1, 2, 3],
  razorPayKey:"rzp_test_CIPeano07KEfR9",
  totalAttachmentSizePerDocument: 10485760
};
