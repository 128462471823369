import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentUser.plan || !currentUser.isPrimaryUser)
        // logged in so return true
        return true;
      else
        this.router.navigateByUrl('/auth/plan-selection');
    }
    else
      this.router.navigate(['/auth/login'], {
        queryParams: {
          returnUrl: state.url
        }
      });
    // not logged in so redirect to login page with the return url
    //this.authService.logout();
    return false;
  }
}
